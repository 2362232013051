.add-rent-date-buttons{
    /* border: 2px solid #dddddd; */
    display: flex;
    justify-content: space-between;
}
.add-rent-date-buttons button{
    /* background-color: #1A4870; */
    /* color: #FFFFFF; */
    border: 2px #7f8183 solid; 
    color: #030303;
    /* border: 2px gainsboro solid; 
    color: gainsboro; */
    width: 9rem;
    height: 2rem;
    border-radius: .2rem;
    line-height: 1.5rem;
    padding-top: .1rem;
    padding-bottom: .1rem;
    /* padding-left: 1rem;
    padding-right: 1rem; */
    text-align: center;
    margin: .2rem;
    cursor: pointer;
}
.add-rent-date-buttons button:hover{
    background-color: gainsboro; 
}

.add-rent-date-buttons button.primary-btn{
    background-color: #5B99C2;
    border: 2px #5B99C2 solid;
    padding-left: 0.375rem;
    padding-right:0.375rem;
}
.add-rent-date-buttons .primary-btn:hover{
    background-color: #5B99C2;
    border: 2px #5B99C2 solid;
    cursor:default;
}

/* load animation */
.loader{
    position:absolute;
    left: 50%;
    top: 45%;

}

.load-animation-prop{
    color: #5B99C2;
    font-size: 2.5rem;
    font-weight: 900;
}