.register-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.brand-box {
    flex-shrink: 2;
}

h1.brand-title {
    color: #1A4870;
    font-weight: bolder;
    margin: 0;
}

h2.brand-motto{
    margin-top: .2rem;
    font-size: 28px;
    font-weight: normal;
    line-height: 32px;
}

.right-side{
    flex-grow: 2;
    flex-shrink: 1;
    width: 30%;
    max-width: 33rem;
    height: 55%;
    max-height: 65%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: .2rem;
}

.register-box {
    border: 2px solid #1A4870;
    width: 90%;
    height: 90%;
    max-height: 90%;
    border-radius: .5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .2rem;
}

.register-input-group {
    flex: 1;
    width: 60%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    input,
    button.register-button,
    hr {
        min-width: 100%;
    }
}

input {
    background-color: white;
    color: black;
    outline: none;
    border: solid #1A4870 2px;
    border-radius: .2rem;
    height: 2rem;
    padding-right: 1rem;
    text-align: left;
}

input:focus {
    outline: none;
    border: 3px solid #5B99C2;
}

h2.register-title{
    margin: 0;
    color: #1A4870;
}

.register-hr {
    margin-top: 1rem;
    border: 1px solid #1A4870;
}

.error-p{
    margin: .2rem;
    color: red;
}

.register-p{
    font-weight: 500;
    margin-bottom: 1rem;
}

.register-input-group .register-p:nth-child(n+2){
 margin:0 ;
 padding: 0;
}

.to-register{
    color: #163b5c;
}

.email{
    text-transform: lowercase;
}

