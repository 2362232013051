.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(61, 59, 59, 0.18);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-out;
    position: relative;
    border-radius: .8rem;
    flex-grow: 1;
    margin: .2rem;
    padding: .2rem;
    display: flex;
    
    flex-direction: column;
    gap: .2rem;

}

/* close button */
.modal-close-button {
    flex: 1;
    max-height: 2rem;
    display: flex;
    justify-content: end !important;
    padding-top: .3rem;
    padding-right: .3rem;
}

.close-button {
    min-width: fit-content;
    border-radius: .4rem;
    color: #dce1e6;
    background-color: rgb(175, 21, 21);
    border: 2px solid rgb(175, 21, 21);
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #FFFFFF;
    background-color: #f50202;
    border: 2px solid #f50202;
}

.close-button:active {
    border: 2px solid #FFFFFF;
    border-radius: .4rem;
}

.modal-children {
    flex-grow: 1;

    min-height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .8rem;
    overflow-y: scroll;
}

input {
    background-color: white;
    color: black;
    outline: none;
    border: solid #1A4870 2px;
    border-radius: .2rem;
    height: 2rem;
    padding-right: 1rem;
    text-align: left;
}

input:focus {
    outline: none;
    border: 3px solid #5B99C2;
}

.input-group {
    flex-grow: 1;

    background-color: rgb(235, 235, 235);
    border-radius: .8rem;
    padding: .5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    flex-basis: fit-content;
    width: 100%;
    min-height: 100%;
}


.lable-and-input {
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    min-width: 24rem;
    max-width: 40rem;
    max-height: fit-content;

    input {
        min-width: 100%;
        background-color: #FFFFFF;
    }

}

#lable-input-select { /*for the drop down/select*/
    .css-b62m3t-container {
        flex-grow: 1;
        width:24rem ;
        max-height: 2rem;
    }
    
    .css-13cymwt-control{
        border: #1A4870 solid 2px;
    }
}

.component-title{
    font-weight:bold ;
    color: #1A4870;
    margin-top: 1rem;

}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* media queries */
/* Base style*/
.modal-content {
    max-width: 45rem;
    height: 65%;
    max-height: 70%;
}



/* Styles for tablets*/
@media (min-width: 601px) and (max-width: 1024px) {
    .modal-content {
        max-width: 45rem;
        height: 65%;
        max-height: 70%;
    }

    .lable-and-input {
        min-width: 24rem;
        max-width: 40rem;
    }
}

/* Styles for phones*/
@media (max-width: 600px) {
    .modal-content {
        max-width: 98dvw;
        height: 65%;
        max-height: 70%;
    }

    .lable-and-input {
        min-width: 80dvw;
        /* max-width: 40rem; */
    }

    #lable-input-select {
        .css-b62m3t-container {
            flex-grow: 1;
            width:80dvw ;
            max-height: 2rem;
        }
    }
    
}