.main-sign-in-container{
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.sign-in-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.brand-box {
    flex-shrink: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1.brand-title {
    color: #1A4870;
    font-weight: bolder;
    margin: 0;
}

h2.brand-motto{
    margin-top: .2rem;
    font-size: 28px;
    font-weight: normal;
    line-height: 32px;
    text-align: center;
}

.right-side{
    flex-grow: 2;
    flex-shrink: 1;
    width: 30%;
    max-width: 33rem;
    height: 55%;
    max-height: 65%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: .2rem;
}

.sign-in-box {
    border: 2px solid #1A4870;
    width: 90%;
    height: 90%;
    max-height: 90%;
    border-radius: .5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .2rem;
}

.sign-in-input-group {
    flex: 1;
    width: 55%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    input,
    button.sign-in-button,
    hr {
        min-width: 100%;
    }
}

input {
    background-color: white;
    color: black;
    outline: none;
    border: solid #1A4870 2px;
    border-radius: .2rem;
    height: 2rem;
    padding-right: 1rem;
    text-align: left;
}

input:focus {
    outline: none;
    border: 3px solid #5B99C2;
}

h2.sign-in-title{
    margin: 0;
    color: #1A4870;
}

.sign-in-hr {
    border: 1px solid #1A4870;
}

.error-p{
    margin: .2rem;
    color: red;
}

.privacy-and-about{
    display: flex;
    justify-content: center;
    gap: .5rem;
}