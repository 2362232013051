.accounts-container {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    color: #1A4870;

    .bottom-pad {
        height: 3rem;
    }
}

.title-and-new-entry {
    flex-grow: 1;

    position: sticky;
    height: 2.5rem;
    top: 0rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;

    h3 {
        margin: 0;
    }
}


.filter-bar {
    flex-grow: 1;
    /* border: solid blue 1px; */


    position: sticky;
    top: 2.3rem;
    margin-bottom: .5rem;
    background-color: #FFFFFF;
    min-height: 4rem;
    padding-left: .8rem;
    padding-right: .8rem;
    display: flex;
    flex-direction: column;

    h3 {
        margin: 0;
    }

}

.filter-bar:has(account-filter-pills) {
    height: 6rem;
}


.filters {
    flex-grow: 1;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: .5rem;
    padding-left: .2rem;
    padding-right: .2rem;



}



.filters>div {
    flex-grow: 1;
    flex-basis: 5rem;
    max-width: fit-content;

    /* border: solid red 1px; */


}


.select-combo {
    display: flex;
    gap: .2rem;

    select {
        max-height: 2rem;
        border: 2px solid #1A4870;
        border-radius: .2rem;
    }

    select:active {
        max-height: 2rem;
        border: 2px solid #5B99C2;
    }

}

.date-combo {
    display: flex;
    gap: .1rem;

    input {
        max-width: 8rem !important;
        margin: 0 !important;
        padding: 0 !important;
    }

}

.amount-combo {
    display: flex;
    gap: .1rem;

    input {
        max-width: 8rem !important;
        margin: 0 !important;
        padding: 0 !important;
    }

}

.search-desc{
    input {
        max-width: 8rem !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}

.duration {
    margin: 0;
    padding: 0;
    max-height: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-filter-pills {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    align-items: center;
    /* border: solid 1px red; */
    background-color: #FFFFFF;
    padding-top: .2rem;
    padding-bottom: .2rem;
    gap: .2rem;
    /* height: 3rem; */
}

.filter-pill-secondary {
    background-color: #5B99C2;
    color: #FFFFFF;
    border-radius: 2rem;
    height: 1.7rem;
    padding-left: .2rem;
    padding-right: .2rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    line-height: 2rem;
    gap: .2rem;
}

.filter-key-value {
    display: flex;
    align-items: center;
    height: 1.5rem;
}

.remove-filter {
    color: #5B99C2;
    background-color: #FFFFFF;
    /* border: solid #5B99C2 1px; */
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.remove-filter:hover {
    background-color: gainsboro;
}

.remove-filter:active {
    background-color: gainsboro;
    width: 1.3rem;
    height: 1.3rem;
}

/* new */
.search-area {
    display: flex;
    justify-content: center;
    gap: .2rem;
    padding-top: .4rem;
    padding-bottom: .2rem;
    background-color: #FFFFFF;
}

.input-area {
    flex-grow: 1;
    max-width: 40rem;
    padding-left: 3rem;

    display: flex;
    justify-content: center;

    input {
        flex-grow: 1;
        text-align: center;
    }

    .clear-search {
        width: 3rem;
    }

}

.accounts {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    gap: .5rem;
    border-radius: 1.2rem;
    padding: .5rem;
    /* margin: .5rem; */
    margin-bottom: 1rem;
    background-color: #FFFFFF;
    /* background-color: rgb(219, 219, 219); */

}

.accounts-main-title {
    padding-left: 1rem;
    margin-top: .5rem;

    h3 {
        margin: 0;
    }
}

.accounts-titles {
    flex-grow: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: .5rem;
    padding-right: .5rem;
    height: 1rem;
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem;
    gap: .5rem;
    /* background-color: rgb(235, 235, 235); */
    /* background-color: #FFFFFF; */
    font-weight: bold;
    /* border: #1A4870 solid 1px; */

    div {
        cursor: pointer;
        color: #1A4870;
    }

    div:hover {
        cursor: pointer;
        color: #5B99C2;
    }

    p {
        margin: 0;
        display: inline-block;
    }

    div {
        /* border: #1A4870 solid 1px;
        border-radius: 1rem; */
    }
}



.accounts-list {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: .8rem;
    padding: .5rem;
    gap: .5rem;

    background-color: rgb(235, 235, 235);
    /* background-color: #FFFFFF; */
}

.account-entry {
    flex-grow: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    padding: .2rem;
    padding-left: .5rem;
    padding-right: .5rem;
    border-radius: .8rem;
    background-color: #FFFFFF;
}

.account-details-left {
    flex-grow: 1;
    /* border: #1A4870 solid 1px; */

    display: flex;
    justify-content: space-between;

}

.account-index-amount {
    flex-grow: 1;

    padding-right: .2rem;
    max-width: 5rem;
    min-width: 5rem;
    display: flex;
    gap: .2rem;
    /* border: solid 2px red; */

    .account-index {
        display: flex;
        justify-content: end;
        width: 1.5rem;
        /* border: #1A4870 0.5px solid; */
    }

    .account-amount {}

    div:hover {
        cursor: pointer;
        color: #5B99C2;
    }
}

.account-type-house-date {
    flex-grow: 1;

    padding-right: .2rem;
    max-width: 40%;
    /* border: solid 2px red; */
    justify-content: start;

    display: flex;
    gap: .5rem;

    .account-type {
        width: fit-content;

        display: flex;
        justify-content: end;
        align-items: center;

        background-color: #5B99C2;
        color: #FFFFFF;
        border-radius: .8rem;
        height: 1.5rem;
        padding-left: .2rem;
        padding-right: .2rem;
        gap: .2rem;

    }

    .account-type:hover {
        background-color: #6ea6cc;
        color: #FFFFFF;
    }

    .account-house {
        display: flex;
        justify-content: end;

        /* border: #1A4870 0.5px solid; */
    }

    .account-date {}

    div:hover {
        cursor: pointer;
        color: #5B99C2;
    }
}

.account-details-right {
    flex-grow: 1;
    max-width: 40%;
    /* border: #1A4870 solid 1px; */

    display: flex;
    justify-content: start;
}

.account-description {
    display: flex;
}



/* mobile */
@media(max-width:600px) {
    .filter-bar {
        display: none;
    }

    .account-type-house-date {
        max-width: 100%;
        margin-left: 12%;
        justify-content: space-between;
    }

    .account-details-right {
        margin-left: 1.4rem;
        max-width: 100%;
    }

    .account-description {
        flex-grow: 1;
        max-width: 100%;
    }
}