body {
  margin: 0px;
  padding: 0px;
  height: 100vh;
  box-sizing: border-box;
  background-color: #FFFFFF;
  text-transform: capitalize;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


.App {
  box-sizing: border-box;
  height: 99.8vh;
  margin: 0px;
  border-radius: .5rem;
  display: flex;
}

@media (max-width: 600px) {
  .App {
    height: 101vh;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: #1A4870;
  border-radius: .5rem 0 0 .5rem;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  border-bottom: solid #e0dcfc 1px;
}

.nav-limiter {
  max-height: 2.6rem;
}

.content>* {
  margin: 0;
}

.main-content {
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
}

.main-content:has(.mobile-container) {
  /* background-color: rgb(219, 219, 219); */
}



.title-and-stats {
  max-height: 14%;
  flex-basis: fit-content;
  padding-top: .1rem;
  padding-bottom: .1rem;
  display: flex;
  justify-content: space-around;
}

.quick-actions {
  max-height: 14%;
  flex-basis: fit-content;
  padding-top: .1rem;
  padding-bottom: .1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;


}

.quick-actions-left {
  flex-basis: fit-content;
  display: flex;
  gap: .5rem;
  /* test */
}

.quick-actions-right {
  flex-basis: fit-content;
  display: flex;
  gap: .5rem;
  /* test */
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.quick-actions-left .card-item {
  background-color: white;
  color: #5B99C2;
  border: solid #5B99C2 2px;
  border-radius: 2rem;
  line-height: 1.5rem;
  padding-top: .1rem;
  padding-bottom: .1rem;
  padding-left: .2rem;
  padding-right: .2rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: .2rem;
  cursor: pointer;

}

.quick-actions-right .card-item {
  background-color: white;
  color: #5B99C2;
  border-bottom: solid #5B99C2 2px;
  line-height: 1.5rem;
  padding-top: .1rem;
  padding-bottom: .1rem;
  padding-left: .2rem;
  padding-right: .2rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: .2rem;
  cursor: pointer;

}

.quick-actions-left .card-item:hover {
  color: #7eb5da;
  border: solid #7eb5da 2px;

  * {
    color: #7eb5da;
  }
}

.quick-actions-right .card-item:hover {
  color: #7eb5da;
  border-bottom: solid #7eb5da 2px;

  * {
    color: #7eb5da;
  }
}

.card-item.male-active {
  border: solid #5B99C2 2px;
  background-color: rgb(6, 112, 148);
  color: #FFFFFF;

  * {
    color: #FFFFFF;
  }
}

.card-item.female-active {
  border: solid #5B99C2 2px;
  background-color: rgb(151, 71, 151);
  color: #FFFFFF;

  * {
    color: #FFFFFF;
  }
}

.gender-icon {
  color: #5B99C2;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-stat {
  color: #5B99C2;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

}

/* Universal component styling */
a,
link {
  color: #1A4870;
  text-decoration: none;

}

link:hover,
a:hover {
  cursor: pointer;
  color: #5B99C2;
}
.make-bold {
  font-weight: bold;
}


button.primary-btn {
  background-color: #1A4870;
  color: #FFFFFF;
  border: 2px #1A4870 solid;
  min-width: 8rem;
  height: 2rem;
  border-radius: .2rem;
  line-height: 1.5rem;
  padding-top: .1rem;
  padding-bottom: .1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  margin: .2rem;
}

button.primary-btn:hover {
  cursor: pointer;
  background-color: #5B99C2;
  border: 2px #5B99C2 solid;
}

button.primary-btn:active {
  cursor: pointer;
  background-color: #5B99C2;
  border: 2px #FFFFFF solid;
}

button.primary-btn.delete-btn {
  background-color: rgb(175, 21, 21);
  ;
  border: 2px rgb(175, 21, 21);
  ;
}

button.primary-btn.delete-btn:hover {
  background-color: #f50202;
  border: 2px rgb(175, 21, 21);
}

button.primary-btn.delete-btn:active {
  background-color: #f50202;
  border: 2px #FFFFFF solid;
}

button.primary-btn.rent-status:hover {
  cursor: pointer;
  border: 2px #FFFFFF solid;
}

button.primary-btn.rent-status:active {
  cursor: pointer;
  border: 2px #FFFFFF solid;
}

/* select bh drop-down */
select.drop-down {
  margin: 0;
  outline: none;
  width: 9.65rem;
  font-size: .95rem;

  /* border: 2px solid white ; */
  /* Removes the default border when clicked */
  /* border: 1px solid black; */
  /* Optional: Define a custom border */
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0;
  border-radius: 4px;
}

select.drop-down option.dd-option {
  padding-left: 0;
  /* color: red; */
  margin-left: 0;
}

select:focus {
  outline: none;
  /* Ensures no border is shown on focus */
  border-color: #000;
  /* Optional: You can change the border color when focused */
}

.drop-down {
  background-color: #1A4870;
  color: gainsboro;
  border: 0;
}

.drop-down:hover {
  cursor: pointer;
  color: #FFFFFF;

  option:nth-child(1) {
    color: gainsboro !important;
    cursor: pointer !important;
    background-color: #1A4870 !important;
  }
}

select option:nth-child(1):hover {
  color: gainsboro !important;
  cursor: pointer !important;
  background-color: #1A4870 !important;
}

select option:nth-child(2):hover {
  background-color: #1A4870 !important;
}


color-pallet {
  color: #FFFFFF;
  color: #F9DBBA;
  color: #5B99C2;
  color: #1A4870;
  color: #1F316F;
}

/* Custom styles for ToastContainer */
.toast-container {
  position: fixed !important;
  /* Ensure it's fixed on the screen */
  top: 20px;
  /* Adjust vertical positioning */
  right: 20px;
  /* Adjust horizontal positioning */
  z-index: 9999;
  /* Ensure it's above other elements */
  /* pointer-events: none; Allows clicks to pass through the container */
}

/* Optional: Customize toast appearance */
.Toastify__toast {
  pointer-events: auto;
  /* Enable interaction with the toast itself */
}