
.payment-history {
    flex-grow: 1;

     min-height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    background-color: rgb(235, 235, 235);
    border-radius: .8rem;

    h3,
    h4 {
        margin: 0;
    }

}


.name-and-title {
    flex-grow: 1;
    max-height: fit-content; 

    position: sticky;
    top: -0.1rem;
    background-color: rgb(235, 235, 235); 
    padding-left: .8rem;
    padding-right: .8rem;
    margin-top: -0.2rem;
    border-radius: .8rem;
}

.room-occupants-h {
    flex-grow: 1;
     max-height: fit-content;

    /* min-height: 100%; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: .8rem;
    padding: .5rem;
    padding-top: 3rem;
    gap: .5rem;

    background-color: rgb(235, 235, 235);
}

.room-occupant-h {
    flex-grow: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    padding: .2rem;
    padding-left: .5rem;
    padding-right: .5rem;
    border-radius: .8rem;
    background-color: #FFFFFF;
}


.occupant-names-h {
    flex-grow: 1;

    display: flex;
    justify-content: end;

    padding-right: .2rem;
    max-width: 14rem;
    min-width: 10rem;
    padding-left: .5rem;
    /* padding-right: .5rem; */

    a:hover {
        cursor: pointer;
    }
}

.occupant-balance-h {
    flex-grow: 1;

    display: flex;
    justify-content: start;
    max-width: 9rem;
    min-width: 9rem;
    margin-right: .2rem;

    :hover {
        cursor: pointer;
    }
}

.occupant-balance-h:hover {
    cursor: pointer;
    color: #5B99C2;
}

.occupant-payment-date-h {
    flex-grow: 1;

    margin-right: .2rem;
    max-width: 12rem;
    min-width: 12rem;
}

.occupant-payment-date-h:hover {
    cursor: pointer;
    color: #5B99C2;
}

.add-occupant-payment-h {
    flex-grow: 1;

    display: flex;
    justify-content: end;
    align-items: center;
    max-width: fit-content;
    color: #1A4870;
    margin-right: .2rem;
}

.add-occupant-payment-h:hover {
    cursor: pointer;
    color: #5B99C2;
}

.print-reciept:hover {
    cursor: pointer;
    color: #5B99C2;
}
