.privacy-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;

}

.privacy-content{
    h1, h2, h3{
        color: #5B99C2;
    }

}
