.client-container {
    .bottom-pad {
        height: 3rem;
    }
    color: #1A4870;
}

.search-area {
    display: flex;
    justify-content: center;
    gap: .2rem;
    padding-top: .4rem;
    padding-bottom: .2rem;
    background-color: #FFFFFF;
}

.input-area {
    flex-grow: 1;
    max-width: 40rem;
    padding-left: 3rem;

    display: flex;
    justify-content: center;

    input {
        flex-grow: 1;
        text-align: center;
    }

    .clear-search {
        width: 3rem;
    }

}

.clients {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    gap: .5rem;
    border-radius: 1.2rem;
    padding: .5rem;
    /* margin: .5rem; */
    margin-bottom: 1rem;
    background-color: #FFFFFF;
    /* background-color: rgb(219, 219, 219); */

}

.clients-main-title{
    padding-left:1rem ;
    margin-top: .5rem;
    h3{
        margin: 0;
    }
}
.clients-titles {
    flex-grow: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: .5rem;
    padding-right: .5rem;
    height: 1rem;
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem;
    gap: .5rem;
    /* background-color: rgb(235, 235, 235); */
    /* background-color: #FFFFFF; */
    font-weight: bold;
    /* border: #1A4870 solid 1px; */

    div {
        cursor: pointer;
        color: #1A4870;
    }

    div:hover {
        cursor: pointer;
        color: #5B99C2;
    }
    p{
        margin: 0;
        display: inline-block;
    }

    div{
        /* border: #1A4870 solid 1px;
        border-radius: 1rem; */
    }
}



.client-list{
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: .8rem;
    padding: .5rem;
    gap: .5rem;

    background-color: rgb(235, 235, 235);
    /* background-color: #FFFFFF; */
}

.client{
    flex-grow: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    padding: .2rem;
    padding-left: .5rem;
    padding-right: .5rem;
    border-radius: .8rem;
    background-color: #FFFFFF;
}

.client-details-left{
    flex-grow: 1;
    display: flex;
}
.client-details-right{
    flex-grow: 1;
    display: flex;

    justify-content: space-between;
}


.client-names {
    flex-grow: 1;

    padding-right: .2rem;
    max-width: 14rem;
    min-width: 14rem;
    display: flex;
    gap: .2rem;

    .client-index{
        display: flex;
        justify-content: end;
        width: 1.5rem;
        /* border: #1A4870 0.5px solid; */
    }

    div:hover {
        cursor: pointer;
        color: #5B99C2;
    }
}

.client-phone {
    flex-grow: 1;

    display: flex;
    justify-content: end;
    max-width: 12rem;
    min-width: 4.5rem;
    margin-right: .2rem;

    :hover {
        cursor: pointer;
    }
}

.client-phone:hover {
    cursor: pointer;
    color: #5B99C2;
}

.client-entry-date {
    flex-grow: 1;

    margin-right: .2rem;
    max-width: 12rem;
    min-width: 12rem;
    span{
        text-transform: lowercase;
    }
}

.client-entry-date:hover {
    cursor: pointer;
    color: #5B99C2;
}

.client-room-name {
    flex-grow: 1;

    display: flex;
    justify-content: end;
    align-items: center;
    max-width: fit-content;
    color: #1A4870;
    margin-right: .2rem;
}

.client-room-name:hover {
    cursor: pointer;
    color: #5B99C2;
}

.room-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    /* override default to reduce width */
    button.primary-btn {
        min-width: 6rem;
        padding-left: .2rem;
        padding-right: .2rem;
    }
}

/* mobile */
@media(max-width:600px){
    .client-room-name{
        margin-left: 1.8rem;
    }
    .client-entry-date{
        display: flex;
        justify-content: end;
    }
}
