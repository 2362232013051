.mobile-container {
    background-color: #f5f7fa;
    min-height: 100vh;
    padding-bottom: 3rem;
}

.search-area {
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    position: sticky;
    top: 0;
    /* z-index: 9; */
}

.input-area {
    max-width: 40rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;

    input {
        flex-grow: 1;
        padding: 0.75rem 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.5rem;
        font-size: 0.95rem;
        transition: all 0.2s;
        
        &:focus {
            outline: none;
            border-color: #1A4870;
            box-shadow: 0 0 0 3px rgba(26, 72, 112, 0.1);
        }
    }
}

.quick-actions {
    background-color: #ffffff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
    gap: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    position: sticky;
    top: 2.6rem;
    /* z-index: 9; */
}

.quick-actions-left, .quick-actions-right {
    display: flex;
    gap: 1rem;
}

.card {
    background-color: #f8fafc;
    border-radius: 0.75rem;
    transition: all 0.2s;

    &:hover {
        transform: translateY(-1px);
    }
}

.card-item {
    padding: 0.75rem 1.25rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    border-radius: 0.75rem;
    transition: all 0.2s;
    
    &.male-active {
        background-color: #1A4870;
        color: white;
    }
    
    &.female-active {
        background-color: #5B99C2;
        color: white;
    }
}

.card-stat {
    font-weight: 600;
    min-width: 1.5rem;
    text-align: center;
}

.room {
    margin: 1rem;
    background-color: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
    border: 1px solid #cad2dd;
    overflow: hidden;
    transition: all 0.2s;

    &:hover {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
}

.room-titles {
    padding: 1rem;
    background-color: #f8fafc;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name-gender-edit {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        font-weight: 600;
        color: #1A4870;
    }

    .capacity-bed {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #1A4870;
    }
}

.room-occupants {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background-color: #ffffff;
}

.room-occupant {
    padding: 1rem;
    background-color: #f8fafc;
    border-radius: 0.75rem;
    display: grid;
    grid-template-columns: 2fr 1fr 1.5fr auto;
    gap: 1rem;
    align-items: center;
    transition: all 0.2s;

    &:hover {
        background-color: #f1f5f9;
    }

    &.green-indicator { border-left: 4px solid #22c55e; }
    &.red-indicator { border-left: 4px solid #ef4444; }
    &.gray-indicator { border-left: 4px solid #94a3b8; }
}

/* .occupant-names {
    flex: 1;
    font-weight: 500;
    color: #1e293b;

    a {
        cursor: pointer;
        &:hover {
            color: #5B99C2;
        }
    }
}

.occupant-balance {
    min-width: 6rem;
    text-align: right;
    font-weight: 600;
    color: #1A4870;
} */

.occupant-payment-date {
    color: #64748b;
    font-size: 0.9rem;
}

.room-buttons {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 0.75rem;
    border-top: 1px solid #e2e8f0;

    button {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        font-weight: 500;
        transition: all 0.2s;
        background-color: #1A4870;
        color: white;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: #5B99C2;
        }
    }
}

.show-more-btn {
    padding: 0.5rem;
    text-align: center;
    color: #5B99C2;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        color: #1A4870;
    }
}

.gender-icon {
    width: 1.5rem;
    height: 1.5rem;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.clear-filter-results-icon {
    cursor: pointer;
    color: #64748b;
    transition: all 0.2s;

    &:hover {
        color: #1A4870;
    }
}