.rooms-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: #FFFFFF;
}

.rooms-title {
    display: flex;
    align-items: center;

    position: sticky;
    height: 2.5rem;
    top: 0rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    background-color: #FFFFFF;

    h3 {
        margin: 0;
    }
}


.rooms-title .search-bar {
    flex-basis: 18rem;
    flex-grow: 1;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10rem;
    gap: .2rem;

    input {
        flex-basis: 18rem;
    }

    .search-input-filter {
        width: 10rem;
        white-space: nowrap;
    }
}

.clear-filter-results-btn {
    background-color: #1A4870;
    color: #FFFFFF;
    border-radius: .2rem;
}

.clear-filter-results-icon {
    color: #1A4870;
    font-size: 1.9rem;
}

.clear-filter-results-icon:hover {
    cursor: pointer;
}

.clear-filter-results-icon:active {
    font-size: 1.8rem;
}

.add-room-btn {
    display: flex;
    align-items: center;
    gap: .2rem;
    border: 2.3px solid #1A4870;
    border-radius: .2rem;
    padding-right: .2rem;
    height: 2rem;

}

.add-room-btn:hover {
    cursor: pointer;
    border: 2.3px solid #5B99C2;

    * {
        color: #5B99C2;
    }
}

.add-room-btn:active {
    cursor: pointer;
    border: 1px solid #5B99C2;
}

.title-and-add-room {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-table {
    position: sticky;
    top: 2.3rem;
    max-height: 2rem;

    tr {
        position: sticky;
        top: 1.5rem;
        height: 2rem;
        background-color: #5B99C2;
        color: #FFFFFF;

        display: flex;
        align-items: center;

        td:nth-child(2) {
            border-left: 2px solid #FFFFFF;
        }

        td:nth-child(3) {
            border-right: 2px solid #FFFFFF;
        }
    }
}

.room-details-container {
    display: flex;
}

.header-table,
.rooms-table {
    flex-grow: 1;

    display: flex;

    tr {
        flex-grow: 1;

        display: flex;
        border-bottom: 2px solid #5B99C2;

        td {
            flex-grow: 1;
        }
    }
}


.room-name-column {
    min-width: 7rem;
    max-width: 7rem;

    display: flex;
    align-items: center;
}

.rooms-table .room-name-column {
    /* min-width: 8rem;
    max-width: 8rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        color: #1A4870;
        margin: 0;
    }
}

.room-name-column div:hover {
    cursor: pointer;

    * {
        color: #5B99C2;
    }
}

.header-table .students-col {
    min-width: 16rem;
    max-width: 16rem;
}

.student {
    display: flex;
    flex-grow: 4;
    align-items: baseline;
}

.student-names {
    min-width: 16rem;
    max-width: 16rem;

    a {
        padding-left: .2rem;
    }
}

.students-col {
    border-left: 2px solid #5B99C2;
    border-right: 2px solid #5B99C2;
}



.rent-col {}

.repairs-col {
    min-width: 20%;
    max-width: 20%;

    .repair {
        display: flex;
        flex-direction: column;

        .add-repair-button {
            display: flex;
            justify-content: end;
        }
    }

}

.raw-rent-status-data p {
    display: inline-block;
    margin: 0;
}

.rent-status {
    flex-grow: 1;

    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.rent-status-details {
    flex-grow: 1;

    display: flex;
    align-items: baseline;
    gap: .2rem;
    max-width: max-content;
}

.rent-status-details p#rent-indicator:first-child {
    display: inline;
    padding: 0;
    margin: 0;
    font-size: 21px;
}

.rent-status-details *:nth-child(2) {
    color: #1A4870;
    ;

}

.rent-status-details *:nth-child(2):hover {
    cursor: pointer;
    color: #5B99C2;
}

.rent-status-button {}

.make-bold {
    font-weight: bold;
}

.amount-owed {
    /* border: #1A4870 solid 1px; */
    display: inline-block;
    width: 5rem;
}

/* rent status indicator  */
.gray {
    color: rgb(110, 110, 110);
}

.gray:hover {
    cursor: pointer;
    color: rgb(146, 146, 146);
}

.green {
    color: green;
}

.green:hover {
    cursor: pointer;
    color: rgb(55, 173, 55);
}

.red {
    color: rgb(175, 21, 21);
}

.red:hover {
    cursor: pointer;
    color: rgb(255, 57, 57);
}

.gray:active,
.green:active,
.red:active {
    cursor: pointer;
}