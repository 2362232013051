.sidenav {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #1A4870;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav.open {
  width: 14rem;
}

.sidenav a {
  font-weight: 500;
  color: #c0c0c0;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #ffffff;
}

.bars{
  display: flex;
  align-items: center;
  height: 2.6rem;
  margin-left: .55rem;
  font-size: 30px;
  cursor: pointer;
  color: #1A4870;
}
.bars:hover{
  color: #5B99C2;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
