.vacancy-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    color: #1A4870;
}

.vacancy-title {

    position: sticky;
    height: 2.5rem;
    top: 0rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;

    h3 {
        margin: 0;
    }
}



.vacancies {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    gap: .5rem;
    border-radius: 1.2rem;
    padding: .5rem;
    margin-bottom: 1rem;
    background-color: #FFFFFF;

}

.vacancy-list {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: .8rem;
    padding: .5rem;
    gap: .5rem;

    background-color: rgb(235, 235, 235);
}

.vacancy-entry {
    flex-grow: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;

    padding: .2rem;
    padding-left: .5rem;
    padding-right: .2rem;
    border-radius: .8rem;
    background-color: #FFFFFF;
}

.vacancy-details-left {
    flex-grow: 1;
    max-width: 40%;

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.client-index{
    display: flex;
    justify-content: end;
    width: 1.5rem;
    /* border: #1A4870 0.5px solid; */
}

.vacancy-room {
    flex-grow: 1;
    display: flex;
    /* min-width:40%; */
    max-width: 15rem;
}

.vacancy-gender {
    flex-grow: 1;
    justify-content: start;
    align-items: center;
    padding-right: .2rem;
}

.vacancy-details-right {
    flex-grow: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.vacancy-capacity {}

.vacancy-capacity {}

.vacancy-free {
    color: #FFFFFF!;
    border-radius: .7rem;
    height: 1.5rem;
    padding-left: .2rem;
    padding-right: .2rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: .2rem;
}

/* vacancy color status indicators */
.vacancy-yellow {
    background-color: rgba(231, 231, 12, 0.5);
    color: black;
}

.vacancy-yellow:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 12, 0.5);
    ;
}

.vacancy-green {
    background-color: rgba(0, 128, 0, 0.5);
    /* font-weight: 500; */
    color: black;
}

.vacancy-green:hover {
    cursor: pointer;
    background-color: rgb(55, 173, 55, 0.5);
}

.vacancy-red {
    background-color: rgba(175, 21, 21, 0.5);
    color: black;
}

.vacancy-red:hover {
    cursor: pointer;
    background-color: rgb(255, 57, 57, 0.5);
}

.vacancy-gray:active,
.vacancy-green:active,
.vacancy-red:active {
    cursor: pointer;
}

/* mobile */
@media(max-width:600px) {
    .vacancy-details-left {
        max-width: 100%;
        justify-content: space-between;
    }
    .vacancy-details-right {
        margin-left: 1.5rem;
        justify-content: space-between;
    }

    .vacancy-gender {
        flex-grow: 1;
        max-width: 26%;
        display: flex;
        justify-content: end;
    }

    .account-description {
        flex-grow: 1;
        max-width: 100%;
    }

    .hide-on-mobile{
        display: none;
    }
}