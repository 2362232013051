.calendar-page {
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;

  /* margin-top: 2rem; */
  gap: .2rem;
  padding: .1rem;
}


.calendar-container {
  flex-grow: 1;

  height: 31.5rem;
  max-width: 28rem;

  padding-top: .2rem;
  padding-bottom: .2rem;
  display: flex;
  border-radius: .5rem;
}

.owing-list-container {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  height: 31.5rem;
  /* max-width: rem; */

  padding-top: .2rem;
  padding-bottom: .2rem;
  border-radius: .5rem;
  /* border: #5B99C2 2px solid; */
  /* background-color: #FFFFFF; */
}

.react-calendar {
  flex-grow: 1;
  /* border: 2px solid #1A4870; */
  border-radius: .2rem;
  border: none;
}


.react-calendar__navigation {
  background-color: #5B99C2;

  * {
    color: #FFFFFF;
    font-size: 1.17rem;
    font-weight: 600;
  }
}

.react-calendar__navigation__arrow:hover,
.react-calendar__navigation__label:hover {
  background-color: #8ec2e4 !important;
}

.react-calendar__navigation__arrow:active,
.react-calendar__navigation__label:active {
  background-color: #8ec2e4 !important;
}

.react-calendar__tile {
  height: 4rem;
}


.owing-title {
  position: sticky;
  min-height: 2.75rem;
  height: 2.75rem;
  top: 0rem;

  display: flex;
  align-items: center;
  padding-left: .2rem;

  background-color: #5B99C2;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;

  h3 {
    margin: 0;
    padding: 0;
    color: #FFFFFF;
  }
}

.owing-list {
  flex-grow: 1;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  background-color: #FFFFFF;
  border: #fff solid 2px;
  overflow-y: scroll;
  padding: 0;
}

.owing-li-bottom-pad {
  height: 30rem;
}

.space {
  /* height: 500px; */
}

.react-calendar__tile:has(.event-dot) {
  background-color: #5B99C2;
}

.react-calendar__tile:hover {
  background-color: #8ec2e4 !important;
}

.event-dot {}

.tooltip {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 10;
}


.owing-ul {
  display: flex;
  flex-direction: column;
}

.owing-li {
  flex-grow: 1;
  padding-left: .2rem;
  border-bottom: #1A4870 solid 1px;
  padding-top: .3rem;
  padding-bottom: .3rem;
  /* margin-bottom:.2rem ; */
}

.owing-li:nth-child(odd) {

  /* background-color:rgba(174, 208, 224, 0.918); */
}

/* .owing-list-container li:last-child {
  border-bottom: none;
} */

.owing-li.highlighted {
  background-color: #5B99C2;
  /* Highlight color */
}

.tooltip {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 10;
}


/* new mobile view */
@media (max-width: 600px) {
  .calendar-modal-content{
    min-height: 95%;
  }
  .calendar-page {
    max-height: 100%;
    /* border: solid red 2px; */

    flex-wrap: wrap;
    overflow-y: scroll;
  }
}


@media (max-width: 200px) {



  /* rotated */
  .calendar-page {
    flex-grow: 1;

    max-width: 93dvh;
    max-height: 94dvw;

    min-width: 93dvh;
    min-height: 94dvw;
    /* border: solid green 2px; */

    transform: rotate(-90deg);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;

    gap: .2rem;
    overflow-y: scroll;
  }

  .calendar-container {
    flex-grow: 1;

    max-height: 20rem;
    max-width: 28rem;
    /* border: solid yellow 1px; */

    padding-top: .2rem;
    padding-bottom: .2rem;
    display: flex;
    border-radius: .5rem;
  }

  .owing-list-container {
    flex-grow: 1;

    max-height: 20rem;
    max-width: 40rem;
    /* border: solid red 1px; */

    display: flex;
    flex-direction: column;

    /* height: 31.5rem; */
    /* max-width: rem; */

    padding-top: .2rem;
    padding-bottom: .2rem;
    border-radius: .5rem;
    /* border: #5B99C2 2px solid; */
    /* background-color: #FFFFFF; */
  }

  .react-calendar {
    flex-grow: 1;
    /* border: 2px solid #1A4870; */
    border-radius: .2rem;
    border: none;
  }


  .react-calendar__navigation {
    position: sticky;
    min-height: 2.75rem;
    height: 2.75rem;
    top: 0rem;

    background-color: #5B99C2;

    * {
      color: #FFFFFF;
      font-size: 1.17rem;
      font-weight: 600;
    }
  }

  .react-calendar__navigation__arrow:hover,
  .react-calendar__navigation__label:hover {
    background-color: #8ec2e4 !important;
  }

  .react-calendar__navigation__arrow:active,
  .react-calendar__navigation__label:active {
    background-color: #8ec2e4 !important;
  }

  .react-calendar__tile {
    height: 3.5rem;
  }


  .owing-title {
    position: sticky;
    min-height: 2.75rem;
    height: 2.75rem;
    top: 0rem;

    display: flex;
    align-items: center;
    padding-left: .2rem;

    background-color: #5B99C2;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;

    h3 {
      margin: 0;
      padding: 0;
      color: #FFFFFF;
    }
  }

  .owing-list {
    flex-grow: 1;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    background-color: #FFFFFF;
    border: #fff solid 2px;
    overflow-y: scroll;
    padding: 0;
  }

  .owing-li-bottom-pad {
    /* height: 30rem; */
  }

  .space {
    /* height: 500px; */
  }

  .react-calendar__tile:has(.event-dot) {
    background-color: #5B99C2;
  }

  .react-calendar__tile:hover {
    background-color: #8ec2e4 !important;
  }

  .event-dot {}

  .tooltip {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 5px;
    z-index: 10;
  }


  .owing-ul {
    display: flex;
    flex-direction: column;
  }

  .owing-li {
    flex-grow: 1;
    padding-left: .2rem;
    border-bottom: #1A4870 solid 1px;
    padding-top: .3rem;
    padding-bottom: .3rem;
    /* margin-bottom:.2rem ; */
  }

  .owing-li:nth-child(odd) {

    /* background-color:rgba(174, 208, 224, 0.918); */
  }

  /* .owing-list-container li:last-child {
      border-bottom: none;
    } */

  .owing-li.highlighted {
    background-color: #5B99C2;
    /* Highlight color */
  }

  .tooltip {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 5px;
    z-index: 10;
  }

}