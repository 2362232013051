.info-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: space-between;
    padding: 1rem;
}

.property {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: .5rem;
    padding: .5rem;

    /* border: solid black 2px; */

    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(51, 50, 50, 0.1);
}

.property-info {
    flex: 1;
    max-width: 20%;
    /* border: red 2px solid; */

}

.update-property {
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;

    /* border: blue 2px solid; */
}

.input-items {
    display: flex;
    flex-direction: column;

    /* border: green 2px solid; */
    /* justify-content:center; */
    align-items: center;

    .label-and-input {
        flex-grow: 1;
        width: 95%;

        display: flex;
        flex-direction: column;
        *{
           min-width: 100%;
        }
    }
}

.property-stats {
    flex-grow: 1;
    max-width: 60%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 5rem;
}

.info-card {
    width: 14rem;
    max-width: 15rem;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;

}

.icon {
    font-size: 30px;
    color: #1A4870;
    margin-bottom: 10px;
}

/* .info-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.info-cards .card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.icon {
    font-size: 30px;
    color: #4caf50;
    margin-bottom: 10px;
}

.primary-btn {
    margin-top: 10px;
} */

@media(max-width:600px) {
    .property-info {
        max-width: 100%;

    }
    
    .property-stats{
        max-width: 100%;
        justify-content:space-between;
        gap: .2rem;
    }

    .info-card {
        /* width: 5rem; */
        max-width: 7rem;
        background-color: #f9f9f9;
        padding: .2rem;
        border-radius: .8rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
    
    }

    
    .icon {
        font-size: 25px;
        color: #1A4870;
        margin-bottom: .1rem;
    }
}

@media(min-width:601px) and (max-width: 900px) {
    .property-info {
        max-width: 40%;

    }
    
    .property-stats{
        max-width: 60%;
        justify-content:space-between;
        gap: .2rem;
    }

    .info-card {
        /* width: 5rem; */
        max-width: 12rem;
        background-color: #f9f9f9;
        padding: .2rem;
        border-radius: .8rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
    
    }

    
    .icon {
        font-size: 25px;
        color: #1A4870;
        margin-bottom: .1rem;
    }
}

@media(min-width:901px) and (max-width: 1500px) {
    .property-info {
        max-width: 40%;

    }
    
    .property-stats{
        max-width: 60%;
        justify-content:space-between;
        gap: .2rem;
    }

    .info-card {
        /* width: 5rem; */
        max-width: 12rem;
        background-color: #f9f9f9;
        padding: .2rem;
        border-radius: .8rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
    
    }

    
    .icon {
        font-size: 25px;
        color: #1A4870;
        margin-bottom: .1rem;
    }
}