/* .sidebarT {
  width: 14rem;
  transition: width ease-in-out .3s;

  display: flex;
  flex-direction: column;
}

.sidebarT a {
  color: gainsboro;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.sidebarT.collapsed {
  width: 3.2rem;

  a:nth-child(even), .small-icon {
    opacity: 0;
    visibility: hidden;
  }
} */

.sidebar-title {
  flex-grow: 1;
  max-height: 2.5rem;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #5B99C2;
  color: #FFFFFF;
}

.custom-h2 {
  margin: 0;
  width: 8rem;
  display: inline-block;
  font-size: 1.875rem;
  font-weight: bold;

  opacity: 1;
  visibility: visible;
  transition: width .4s ease-in-out, opacity 0.2s ease-in-out .2s, visibility 0s ease-in-out .2s;
}

.sidebar-button {
  background-color: #5B99C2;
  color: gainsboro;
  padding: .2rem;
  padding-right: .5rem;
}

.sidebarT a:hover,
.sidebar-button:hover {
  cursor: pointer;
  color: #FFFFFF;
}

.icon-class {
  font-size: 1.3rem;
  padding: .2rem;
}

.icon-class-xl {
  font-size: 1.8rem;
}

.collapsed .sidebar-title .custom-h2 {
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition: width .2s ease-in-out, opacity 0.1s ease-in-out .1s, visibility 0s ease-in-out .1s;
}

.collapsed .sidebar-title .sidebar-button {
  padding: .2rem;
  padding-right: .8rem;
}

.sidebar-ul {
  margin-top: .4rem;
  margin-left: .5rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sidebar-li {
  white-space: nowrap;

  display: flex;
  align-items: center;
}

/* dropdown element */
.sidebar-li:nth-child(6) {
  white-space: nowrap;

  display: flex;
  align-items: normal;
  flex-direction: column;

  /* limit title width to align dropdown icon */
  a.drop-down-title{
    max-width: 8.5rem;
  }
  /* reduce dropdown icon size */
  .small-icon{
    font-size: .825rem;
  }
}

/* icon and cooresponding title */
.sidebar-li a:nth-child(odd) {
  min-width: 2rem;
  width: 2rem;

  text-align: center;
}

.sidebar-li a:nth-child(even) {
  flex-grow: 1;
  padding-left: .5rem;
}

/* dropdown ctrl */

.dropdown{
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-top:.5rem ;
  margin-left: .8rem;
  transition: height ease-in-out .9s;
  /* background-color:rgba(75, 192, 192, 0.5); */
}