.nav {
  display: flex;
  justify-content: end;
  padding-left: .5rem;
  /* border-bottom: solid #e0dcfc 1px; */
}

.shared-nav {
  height: 2.5rem;
}

.profile-and-notifications {
  display: flex;
  align-items: center;
  gap: .5rem;

  margin-right: .99rem;
}

.blue-icons {
  color: #1A4870;

  :hover {
    color: #5B99C2;
    cursor: pointer;
  }
}

/* profile icon logout */

.profile-dropdown {
  position: relative;
  display: inline-block;
  z-index: 5;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #1A4870;
  border: 1px solid #ccc;
  border-radius: .2rem;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 150px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 15;
}

.dropdown-item {
  background: none;
  color: gainsboro;
  border: none;
  width: 100%;
  text-align: left;
  margin-left: .5rem;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;

  display: flex;
  gap: .5rem;
  align-items: center;

}

.dropdown-item:hover {
  /* background-color: #5B99C2; */
  color: #FFFFFF;
}