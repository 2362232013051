.accounts-reports-container {
    display: flex;
    min-height: 100%;
    /* border: solid blue 2px; */
    color: #1A4870;
    padding: 1rem;

}

.graph-and-title {
    flex-grow: 1;
    width: 100%;
    min-height: 100%;
    min-width: 100%;

    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: space-between;

    /* border: solid red 2px; */

    h2 {
        margin: 0;
    }

}

.first-graph {
    flex-grow: 1;
    align-self: center;
    max-width: 80%;
    min-width: 80%;
    max-height: 70%;
    display: flex;
    justify-content: center;
    /* border: solid green 2px; */
    border-radius: .8rem;

    background-color: #FFFFFF;
    border-radius: .8rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.199);

}

@media(max-width:600px) {
    .graph-and-title{
        justify-content: normal;
    }
    .first-graph {
        flex-grow: 1;
        min-width: 100%;
        max-width: 100%;
        max-height: 80%;
        display: flex;
        justify-content: center;

    }
}